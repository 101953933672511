import { render, staticRenderFns } from "./ProjectSubscription.vue?vue&type=template&id=125d4e8a&scoped=true&"
import script from "./ProjectSubscription.vue?vue&type=script&lang=js&"
export * from "./ProjectSubscription.vue?vue&type=script&lang=js&"
import style0 from "./ProjectSubscription.vue?vue&type=style&index=0&id=125d4e8a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "125d4e8a",
  null
  
)

export default component.exports